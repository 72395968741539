export default (authGuard, guestGuard) => [
  ...guestGuard([
    {
      path: "/login",
      name: "Login",
      // meta: { layout: 'fullscreen' },
      component: () => import(/* webpackChunkName: "login" */ "@/pages/login"),
    },
  ]),

  ...authGuard([
    {
      path: "/",
      name: "Home",

      // meta: { layout: 'app' },
      component: () => import(/* webpackChunkName: "home" */ "@/pages/home"),
    },
    {
      path: "/plantilla",
      name: "Plantilla",
      // meta: { layout: 'app' },
      component: () =>
        import(/* webpackChunkName: "sales" */ "@/pages/plantilla"),
    },
    {
      path: "/ventas",
      name: "sales",
      // meta: { layout: 'app' },
      component: () => import(/* webpackChunkName: "sales" */ "@/pages/sales"),
    },
    {
      path: "/productos",
      name: "products",
      // meta: { layout: 'app' },
      component: () =>
        import(/* webpackChunkName: "products" */ "@/pages/products"),
    },
    {
      path: "/logistica",
      name: "logistic",
      // meta: { layout: 'app' },
      component: () =>
        import(/* webpackChunkName: "logistic" */ "@/pages/logistic"),
    },
    {
      path: "/marketing",
      name: "marketing",
      // meta: { layout: 'app' },
      component: () =>
        import(/* webpackChunkName: "marketing" */ "@/pages/marketing"),
    },
  ]),

  {
    path: "*",
    name: "404",
    redirect: { name: "Home" },
  },
];
