import Service from './Service';
import router from '@/router';

import { state, actions } from '@/store';

import axios from 'axios';

const config = {
    withCredentials: true,
};
const http = axios.create(config);

const setCancelTokens = request => {
    //  Generate cancel token source
    const source = axios.CancelToken.source();

    // Set cancel token on this request
    request.cancelToken = source.token;

    // Add to store to make cancellation available from anywhere
    actions.setCancelToken(source);
};

// Do something before request is sent
const requestInterceptor = request => {
    setCancelTokens(request);
    return request;
};
// Do something with request error
const requestErrorHandler = error => Promise.reject(error);

http.interceptors.request.use(requestInterceptor, requestErrorHandler);

async function doLogout() {
    await actions.resetAuth();
}

// http.interceptors.response.use(null, error => {
//     if (error.response.status === 401) {
//         doLogout().then(res => {
//             router.push({ name: 'Login' }).catch(() => ({}));
//             return Promise.reject(error);
//         });
//     }
// });

class Model extends Service {
    baseUrl() {
        return process.env.VUE_APP_API_URL;
    }

    makeRequest({ method, url, data, headers, query }) {
        return http({
            method,
            url,
            data,
            headers,
            params: query,
        });
    }

    update(attributes, config = {}) {
        return this.request({
            method: 'PUT',
            action: 'update',
            param: this.primaryKey(attributes),
            data: attributes,
            ...config,
        });
    }

    beforeRequest(request) {
        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${state.token}`,
        };
        return request;
    }

    afterRequest({ data }) {
        //console.log('data :>> ', data);
        return data;
    }

    // afterRequest(response, request) {
    //     if (request.action === 'paginate') {
    //         return response.data;
    //     }

    //     return response.data.data;
    // }

    failedRequest(error) {
        const { status } = error.response;

        // Error 401: Unauthorized
        if (status === 401) {
            console.log('#Error 401: Unauthorized');
        }

        // Error 403: Forbidden
        if (status === 403) {
            actions.resetAuth();
            return router.push({
                name: 'Login'
            }).catch(() => ({}));
            console.log('#Error 403: Forbidden');
        }

        // Error 404: Not found
        if (status === 404) {
            console.log('#Error 404: Not found');
        }

        // Error 419: Token mismatch
        if (status === 419) {
            console.log('#Error 419: Authentication Timeout');
        }

        // Error 422: Unprocessable entity
        if (status === 422) {
            console.log('#Error 419: Unprocessable entity');
        }

        // Error 500: Server error
        if (status >= 500) {
            console.log('#Error 500: Server error');
        }

        return Promise.reject(error);
    }
}

export default Model;
