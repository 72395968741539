export const flatten = (args) => {
    return args.reduce((acc, val) => acc.concat(val), []);
};

export const URLJoin = (args) => {
    return args
        .join('/')
        .replace(/\/+/g, '/')
        .replace(/\/$/, '')
        .replace(/^(.+):\//, '$1://')
        .replace(/^file:/, 'file:/')
        .replace(/\/(\?|&|#[^!])/g, '$1')
        .replace(/\?/g, '&')
        .replace('&', '?');
};

export const ProjectViews = [{
        name: 'Ventas',
        id: 'ventas',
    },
    {
        name: 'Producto',
        id: 'producto',
    },
    {
        name: 'Logística',
        id: 'logistica',
    },
    {
        name: 'Marketing',
        id: 'marketing',
    },

]

export const SalesGlobal = {
    current: 635242, // €
    current_percent: 34,
    previous: 234455, // €
    previous_percent: 54,
    growth_percent: 20,
    target_month: 17999.65, // €
    target_gap: 5000, // €
    target_day: 8999, // €
    countries: [{
        id: 0,
        name: 'España',
        value: 123, // €
        percent: 23,
    }, {
        id: 1,
        name: 'Portugal',
        value: 123, // €
        percent: 23,
    }],
    markets: [{
        id: 0,
        name: 'Market1',
        value: 11, // €
        percent: 23,
    }, {
        id: 1,
        name: 'Market2',
        value: 99, // €
        percent: 23,
    }],
    best_day: 'Domingo',
    best_day_value: 103738.72, // €
    best_day_units: 1532, // units
    client: {
        percent: 75,
        growth_percent: -3.5,
    }
}
export const SalesPayment = [{
    id: 0,
    name: 'Método1',
    value: 877, // €
    percent: 87
}, {
    id: 1,
    name: 'Método2',
    value: 120, // €
    percent: 13
}]

export const SalesSource = [{
    id: 0,
    name: 'Fuente 1',
    value: 577, // €
    percent: 45
}, {
    id: 1,
    name: 'Fuente 2',
    value: 678, // €
    percent: 55
}]

export const SalesReturns = {
    current: 89, // units
    growth_percent: 10.5,
    percent: 23,
    return_by_product: {
        products: [{
            id: 0, // que el ID de producto sea el mismo en todos los paneles
            name: 'Nombre del producto1',
            image: 'image...ID???',
            value: 12099, // € total todas devoluciones
            percent: 23
        }, {
            id: 1, // que el ID de producto sea el mismo en todos los paneles
            name: 'Nombre del producto1',
            image: 'image...ID???',
            value: 12099, // € total todas devoluciones
            percent: 23
        }],
        categories: [{
            id: 'cat0', // que el ID de categoría sea el mismo en todos los paneles
            name: 'Categoría1',
            value: 12099, // € total todas devoluciones
            percent: 23
        }, {
            id: 'cat1', // que el ID de categoría sea el mismo en todos los paneles
            name: 'Categoría2',
            value: 12099, // € total todas devoluciones
            percent: 23
        }]
    },
    returns_by_type: [{
        name: 'Motivos',
        types: [{
            id: 0,
            name: 'Motivo 1',
            value: 234, // units ó € ??
            percent: 24,
        }, {
            id: 1,
            name: 'Motivo 2',
            value: 324, // units ó € ??
            percent: 56,
        }, {
            id: 2,
            name: 'Motivo 3',
            value: 11, // units ó € ??
            percent: 14,
        }]
    }, {
        name: 'Proveedores',
        types: [{
            id: 0,
            name: 'Proveedor 1',
            value: 234, // units ó € ??
            percent: 13,
        }, {
            id: 1,
            name: 'Proveedor 2',
            value: 123, // units ó € ??
            percent: 9.2,
        }, {
            id: 2,
            name: 'Proveedor 3',
            value: 77, // units ó € ??
            percent: 15.2,
        }]
    }]
}