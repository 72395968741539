import Vue from 'vue';
import moment from 'moment-timezone';
import {
    toCookie,
    toLocal
} from './storages';

const apiTimeFormat = 'YYYY-MM-DD';

const today = new Date();
const last_day = new Date(today.getFullYear(), today.getMonth() + 1, 0);

const store = {
    apiTimeFormat: apiTimeFormat,
    token: null,
    cancelToken: null,
    user: {
        email: null,
        name: null,
        roles: []
    },
    marketing_evolutive: 'Ingresos',
    tvMode: false,
    catFilter: false,
    selectedMonth: {
        today: today,
        rangeTo: last_day,
        month: moment(today).locale('es').format('MMMM'),
        year: moment().year()
    },
    period: {
        from: moment().startOf('month'),
        to: moment().endOf('month'),
    },
    yesterday: {
        from: moment().subtract(1, 'days'),
        to: today,
    },
    week: {
        from: moment().subtract(6, 'days'),
        to: today,
    },
    last_month: {
        from: moment().startOf('month').subtract(1, 'months'),
        to: moment().startOf('month').subtract(1, 'months').endOf('month'),
    },
    month: {
        from: moment().startOf('month'),
        to: today,
    },
    market: {
        id: null,
        name: '',
    },
    from_category: null,
    to_category: null,
};

const state = Vue.observable(store);

toLocal(state, {
    attrs: ['token'],
});

const actions = {
    setToken(token) {
       
        state.token = token;
    },
    setCancelToken(token) {
        state.cancelToken = token;
    },
    setUser(user) {
        state.user = user;
    },
    setPeriod(period) {
        state.period = period;
    },
    setMonth(selectedMonth) {
        state.selectedMonth = selectedMonth;
    },
    setMarket(market) {
        state.market = market;
    },
    setMarketingEvolutive(marketing_evolutive) {
        state.marketing_evolutive = marketing_evolutive;
    },
    resetAuth() {
        state.token = null;
        state.user = null;
    },
    resetRoles() {
        state.user = null;
    },
    setTvMode(tvMode) {
        state.tvMode = tvMode;
    },
    setCatFilter(catFilter) {
        state.catFilter = catFilter;
    },
    setCategoryFrom(from_category) {
        state.from_category = from_category;
    },
    setCategoryTo(to_category) {
        state.to_category = to_category;
    },

};

export {
    state,
    actions
};