import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { actions, state } from '@/store';
import Auth from '/src/api/models/Auth.js';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes(authGuard, guestGuard),
});

export default router;

router.beforeEach(async (to, from, next) => {
    if (state.token && !state.user) {        
        try {
            const data = await Auth.me();
            actions.setUser(data);
            //console.log('data',data)
        } catch (error) {
            //console.log(error)
            actions.resetAuth();
        }
    }

    next();
});

function beforeEnter(r, callback) {
    return r.map(route => {
        return {
            ...route,
            beforeEnter: callback,
        };
    });
}

 function authGuard(r) {
     return beforeEnter(r, async (to, from, next) => {
         console.log('to :>> ', to);
        if (!state.token) {
            return next({
                name: 'Login',
            });
        }
        if (['Login'].includes(to.name) && state.token) {
            router
                .push({
                    name: 'sales',
                })
                .catch(() => ({}));
        }

        
         if (['Home'].includes(to.name)) {
            
            if (!state.user.name) {
                const data = await Auth.me();
                actions.setUser(data);
            }
            router
                .push({
                    name: state.user.name ? state.user.roles[0] : 'Login',
                })
                .catch(() => ({}));
        }

        next();
    });
}

function guestGuard(r) {
    const preventLogin = ['Login', 'sales'];

    return beforeEnter(r, (to, from, next) => {
        if (!preventLogin.includes(to.name)) {
            router
                .push({
                    name: 'Login',
                })
                .catch(() => ({}));
        }

        next();
    });
}
