import Vue from 'vue';
import App from './App.vue';
import router from './router';

import './assets/css/main.css';

import '@mdi/font/css/materialdesignicons.min.css';

//IMPORTAMOS VUE HIGHCHART, PARA GRÁFICAS
//import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';

Highcharts.setOptions({
    lang: {
        thousandsSep: '.',
        decimalPoint: ',',
        months: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ],
        weekdays: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    },
    time: {
        timezoneOffset: new Date().getTimezoneOffset(),
    },
});

Vue.use(HighchartsVue);
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

import Ripple from 'vue-ripple-directive';
Vue.directive('ripple', Ripple);
Ripple.color = 'rgba(226, 216, 226, 0.25)';

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';
Vue.use(VueAwesomeSwiper);

import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

import VueTippy, { TippyComponent } from 'vue-tippy';
Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
require('moment/locale/es');

Vue.use(VueMoment, {
    moment,
});


import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));
numeral.register('locale', 'es', {
    delimiters: {
        thousands: '.',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: function(number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€',
    },
});
numeral.locale('es');

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
