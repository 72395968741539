<template>
    <div id="app" class="max-w-full w-screen h-full xl:h-screen text-gray-900 overflow-auto">
        <router-view />

        <portal-target name="modals" />
    </div>
</template>

<script>
    export default {
        name: 'App',
        mounted() {
            //document.querySelectorAll('img').forEach(image => {
            //    image.onerror = () => {
            //        this.src = 'img/default.jpg';
            //    };
            //});
        },
    };
</script>
